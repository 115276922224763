















































































import { Vue, Component, Watch } from "vue-property-decorator";
import authModel from "@/models/auth.model";

import AusterInput from "../../components/AusterInput.vue";
import AusterSelect from "../../components/AusterSelect.vue";
import AusterButton from "../../components/AusterButton.vue";
import Navbar from "../../components/Navbar.vue";
import FooterPage from "../../components/FooterPage.vue";
import { validateEntity } from '../../models/entity-validation';

@Component({
  components: {
    AusterInput,
    AusterSelect,
    AusterButton,
    Navbar,
    FooterPage,
  },
})
export default class Signup extends Vue {
  colorLoading:'#2E7179';

  passwordType = "password";
  passwordIcon = "visibility_off";

  fileUrl = "https://media.seuapoio.com/FiqueOk-Online_Termo-Privacidade-V2.pdf";

  loading = false;

  userInfo: any = {
    name: "",
    phoneNumber: "",
    //cpf: "",
    birthDate: "",
    gender: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    terms: false,
    userType: "EMPLOYEE",
    defaultPassword: ""
  };

  genderOptions: any[] = [{ name: 'Masculino', value: 'M' }, { name: 'Feminino', value: 'F' }, { name: 'Outro', value: 'OTHER' }];
  userTypeOptions: any[] = [{ name: 'Funcionário', value: 'EMPLOYEE' }, { name: 'Dependente', value: 'DEPENDENT' }];

  translateGender(value: string) { // necessary to due vs-select bug
    switch (value) {
      case "M":
        return "MALE";
      case "F":
        return "FEMALE";
      default:
        return "OTHER";
    }
  }

  fieldErrors: any = {};

  validateFields() {
    const validationDefinition: any = [
      { key: "name", validation: { lengthMax: 150 }},
      { key: "phoneNumber", validation: { kind: 'phone' } },
      { key: "cpf", validation: { kind: 'cpf' } },
      { key: "birthDate", validation: { kind: 'brDate' } },
      { key: "gender", validation: {} },
      { key: "email", validation: { kind: 'email' } },
      { key: "password", validation: { lengthMin: 8, lengthMax: 100 } },
      { key: "passwordConfirmation", validation: { kind: "match", matchKey: "password" } },
      { key: "terms", validation: {} },
      { key: "register", validation: {} },
      { key: "userType", validation: {} },
      { key: "defaultPassword", validation: { required: true } },
    ];

    //this.fieldErrors = validateEntity(this.userInfo, validationDefinition, ["name", "phoneNumber", "cpf", "birthDate", "gender", "email", "password", "passwordConfirmation", "terms"]);
    this.fieldErrors = validateEntity(this.userInfo, validationDefinition, ["name", "phoneNumber", "birthDate", "gender", "email", "password", "passwordConfirmation", "terms", "register", "userType", "defaultPassword"]);
  }

  @Watch('userInfo', { deep: true })
  userInfoChanged() {
    // if a previous error was found, update validation when data changes
    if (Object.keys(this.fieldErrors).length) {
      this.validateFields();
    }
  }

  async signup() {
    //console.log(this.userInfo);
    this.validateFields();
    if (Object.values(this.fieldErrors).filter(error => !!error).length) {
      this.$vs.notification({
        text: "Verifique os erros indicados",
        color:"#FF6767",
      });
      console.error(this.fieldErrors);
      return;
    }
    this.loading = true;
    try {
      const session = await authModel.signup({ ...this.userInfo, birthDate: this.userInfo.birthDate.split("/").reverse().join('-'), terms: undefined, passwordConfirmation: undefined, gender: this.translateGender(this.userInfo.gender) });
      this.$vs.notification({ text: "Cadastro realizado!",  color:"#2E7179" });
      await this.$store.dispatch("auth/setSession", session);
    } catch (error) {
      console.error(error);
      if (error.statusCode === 409) {
        this.$vs.notification({ text: "CPF ou email já cadastrados.", color:"#FF6767" });
      }
      else if (error.statusCode === 400 && error.message) {
        this.$vs.notification({ text: error.message, color:"#FF6767" });      
      } else {
        this.$vs.notification({ text: "Ocorreu um erro. Tente novamente!", color:"#FF6767" });
      }
    }
    this.loading = false;
  }

  showPassword() {
    if (this.passwordType === "password" && this.passwordIcon === "visibility_off") {
      this.passwordType = "text"; this.passwordIcon = "visibility"
    } else {
      this.passwordType = "password"
      this.passwordIcon = "visibility_off"
    }
  }

  openTerms() {
    window.open(this.fileUrl, "_blank");
  }
}
